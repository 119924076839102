<template>
  <div style="width: -webkit-fill-available">
    <!-- toolbar -->
    <v-toolbar dense flat>
      <v-toolbar-title>{{ $t("drawer.Results") }}</v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-container fluid>
      <v-row>
        <!-- model select -->
        <v-col>
          <ModelSelect
            class="model-list-select"
            :id="id"
            :datasetGroupId="datasetGroupId"
            @onModelChange="onModelChange"
            @onTypeChange="onTypeChange"
            @onSelectDatasetGroup="onSelectDatasetGroup"
            @onResetDefaultInfo="onResetDefaultInfo"
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- model select info -->
        <template v-if="showDefaultInfo">
          <v-col>
            <v-alert
              type="info"
              dense
              outlined
              class="text-center"
              color="primary"
            >
              <div>Please select a model to show results!</div>
            </v-alert>
          </v-col>
        </template>

        <!-- 404 not found -->
        <template v-else-if="loaded && !hasData">
          <v-container fluid>
            <v-row>
              <v-col>
                <v-alert type="error" dense outlined class="text-center">
                  <div>Unable to find the resource you requested</div>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <!-- loading animation -->
        <template v-else-if="!loaded">
          <v-container fluid style="height: 100%">
            <v-row align="center" justify="center" style="height: 100%">
              <v-col class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <!-- causal graph -->
        <template v-else-if="modelType === 'causal_discovery'">
          <v-col cols="12">
            <DatasetGroupsCausalEditor
              :isEdit="false"
            ></DatasetGroupsCausalEditor>
          </v-col>
        </template>

        <!-- data content -->
        <template v-else>
          <v-col cols="12">
            <!-- tabs -->
            <v-tabs v-model="tab" show-arrows>
              <v-tab v-for="tab in tabs" :key="tab.icon">
                <v-icon size="20" class="me-3">
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>

            <!-- tabs item -->
            <v-tabs-items v-model="tab">
              <v-container v-if="tab === 0" class="px-0">
                <Summary />
              </v-container>
              <v-container v-if="tab === 1" class="px-0">
                <PromoOverview />
              </v-container>
              <v-container v-if="tab === 2" class="px-0">
                <SpendSimulation />
              </v-container>
              <v-container v-if="tab === 3" class="px-0">
                <ResultsFiles />
              </v-container>
            </v-tabs-items>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Results",
  components: {
    ModelSelect: () => import("@/views/results/ModelSelect"),
    Summary: () => import("@/views/results/summary/Summary"),
    PromoOverview: () => import("@/views/results/promo-overview/PromoOverview"),
    SpendSimulation: () =>
      import("@/views/results/spend-simulation/SpendSimulation"),
    ResultsFiles: () => import("@/views/results/results-files/ResultsFiles"),
    DatasetGroupsCausalEditor: () =>
      import(
        "@/views/dataset-groups/dataset-groups-tab-causal-graph/DatasetGroupsCausalEditor"
      ),
  },
  data: () => ({
    tab: null,
    tabs: [
      { title: "Summary", icon: "mdi-text-box-check-outline" },
      { title: "Kairos Lift", icon: "mdi-text-box-search-outline" },
      { title: "Kairos ROI", icon: "mdi-flask-plus-outline" },
      { title: "Results Files", icon: "mdi-file-download-outline" },
    ],
    showDefaultInfo: true,
    hasNoResources: null,
    // loaded: true,
    id: null,
    // modelType: "causal_discovery",
    modelType: null,
    loaded: null,
    hasData: false,
    selectDatasetGroup: null,
    datasetGroupId: null,
  }),
  methods: {
    onModelChange() {
      console.log("onModelChange");
      this.showDefaultInfo = false;
      this.tab = 0;
    },
    onTypeChange(type) {
      console.log("onTypeChange");
      if (type === "causal_discovery") {
        this.$router.replace({
          query: { id: this.id, datasetGroupId: this.selectDatasetGroup.id },
        });
        console.log("causal_discovery");
      } else if (type === "mmm") {
        console.log("mmm");
      } else {
        console.log("others");
      }
      this.modelType = type;
      this.loaded = true;
      this.hasData = true;
    },
    onSelectDatasetGroup(item) {
      this.selectDatasetGroup = item;
    },
    onResetDefaultInfo() {
      this.showDefaultInfo = true;
    },
  },
  computed: {
    loading() {
      return this.$store.state.loader.loading;
    },
    // loaded() {
    //   return this.$store.state.results.loaded;
    // },
    // hasData() {
    //   return this.$store.getters.hasData;
    // },
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      datasetName: this.selectDatasetGroup
        ? this.selectDatasetGroup.name
        : null,
    };
  },
  created() {
    const query = this.$route.query;
    if (Object.keys(query).length !== 0) {
      this.id = query.id;
      this.datasetGroupId = query.datasetGroupId;
    }
  },
};
</script>

<style scoped>
/* .model-list-select {
  max-width: 400px;
} */
</style>
